<template>
    <div>
      <div class="banner-section">
		<div class="banner-image">
			<img src="../assets/images/banner-bg.jpg">
		</div>
		<div class="banner-item">	
			<div class="container">						
				<div class="banner-title">
					<h2>Trucking logistics <span>at its best.</span></h2>
					<p>Discover a better way of shipping with Seattle Choice.<br> Our mantra, Safe and Seamless deliveries.</p>
					<!-- <a @click="ShowModal();">Request a Quote</a> -->
				</div>
			</div>
		</div>
	</div>
	<div class="choice-section">
		<div class="container">
			<div class="section-title">
				<h2>Why Seattle Choice?</h2>
				<p>Experience premium trucking logistics and shipping services</p>
			</div>
			<div class="content-section">
				<div class="safty">
					<div class="image">
						<img src="../assets/images/safty-images.png">
					</div>
					<div class="choice-content">
						<h2>Safety</h2>
						<p>"Safety first, quality always" is our watchword. By "safety," we mean the safety of our team and your goods. This makes part of the culture we have built for our customers and drivers, and it is sacred.</p>
					</div>
				</div>
				<div class="safty">
					<div class="image">
						<img src="../assets/images/on-time-travel.png">
					</div>
					<div class="choice-content">
						<h2>On time delivery</h2>
						<p>Not only do we deliver your goods in one piece, we do so on time. Our customers' happiness and satisfaction is a priority for us— a customer who gets a timely delivery is a happy one. Hence, we are committed to delivering your goods and our services in good time.</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="choice-section" id="our-feet">
		<div class="container">
			<div class="section-title">
				<h2>Our Fleet</h2>
				<p>Our fleet consists of modern trucks which undergo consistent maintenance and changes. This lessens the possibility of technical issues with our equipment. We currently own and operate a number of 53ft dry vans, reefers and trucks</p>
			</div>
			<div class="fleet-image">
				<img src="../assets/images/truck-fleet.jpg">
			</div>
		</div>
	</div>

	<div class="careers-section">
		<div class="container">
			<div class="section-title">
				<h2>Careers and Opportunities</h2>				
			</div>
			<div class="careers-flex">
					<div class="column-inner">
						<div class="image">
							<img src="../assets/images/earning.png">
						</div>
						<h2>Earn</h2>
						<p>We offer a competitive base salary of $49k yearly, driving three times a week. If your goal is to be financially free, you’ve got to start taking steps to achieve that – join us now. You can also earn up to $500 when you refer a driver.</p>
					</div>
					<div class="column-inner">
						<div class="image">
							<img src="../assets/images/driving.png">
						</div>
						<h2>Work with us</h2>
						<p>Our employees are provided with access to financial freedom, learning experiences and several opportunities to grow their careers. If you are great with wheels and devoted as we are to being the very best, then you should work with us.</p>
				</div>
					<div class="column-inner">
						<div class="image">
							<img src="../assets/images/working.png">
						</div>
						<h2>Become a Driver</h2>
						<p>We consider drivers heroes and we are hiring! As a hero for Seattle Choice Transport, you are required to always save the day by safely and efficiently driving commercial trucks. To be our hero, possessing a positive and friendly attitude, and great client service are compulsory qualities.</p>
				</div>
			</div>
			<div class="join-button">
				<!-- <a @click="goToBecomeDriver()" class="join-us-button">Join Us</a> -->
			</div>
		</div>
	</div>

	<SettleSlider />
	<AgencySlider />
    </div>
</template>

<script>
const SettleSlider = () => import("@/components/SettleSlider");
const AgencySlider = () => import("@/components/AgencySlider");

export default {
  name: 'Home',
  components: {
	SettleSlider,
	AgencySlider,
  },
  methods: {
	ShowModal() {
		this.$modal.show('QuoteRequestModal')
	},
	goToBecomeDriver(){
		this.$router.push({ path: "/become-driver" });
	},
  }
}
</script>
